import React, { useState } from "react";
import CreateNewRoom from "./CreateNewRoom";
import ActiveRooms from "./ActiveRooms/ActiveRooms";
import { useEcard } from "../../../providers/EcardProvider";
import { ecardSocketEventNames } from "../../../socketCommunication/ecardSocket";
import "./ecardLobby.css";
import SpikesDown from "../../../components/SpikesDown";
import MenuButton from "../../../components/MenuButton";
import DialogComponent from "../../../components/dialogs/DialogComponent";
import { dispatchCustomEventListener } from "../../../utils/utils";
import { config } from "../../../config";
import UserJoinedDialog from "./dialogs/UserJoinedDialog";
import DeclineDialog from "./dialogs/DeclineDialog";

function EcardLobby() {
  const { sendSocketMessage } = useEcard();
  const [createNewDialogOpened, setCreateNewDialogOpened] = useState(false);
  const [userJoinedDialogOpen, setUserJoinedDialogOpen] = useState(false);
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);

  const [isJoinDisabled, setIsJoinDisabled] = useState(false);

  const handlePlayVsBot = () => {
    sendSocketMessage({
      eventName: ecardSocketEventNames.room.joinVsBot,
      data: {},
    });
  };

  const handleShowUserJoinedDialog = (a?: any) => {setUserJoinedDialogOpen(true)};
  const handleShowDeclineDialog = (a?: any) => {setIsJoinDisabled(false);setUserJoinedDialogOpen(false);setDeclineDialogOpen(true)};

  const handleDialogObj = [
    {
      eventName: config.customDispatch.showUserJoinedDialog,
      fallback: handleShowUserJoinedDialog,
    },
    {
      eventName: config.customDispatch.showDeclineDialog,
      fallback: handleShowDeclineDialog,
    },
  ];

  const dialogListener = (event: any) => {
    //p:{eventName:string, fallback:(a:any)=>void}[], event:CustomEvent<any>
    dispatchCustomEventListener(handleDialogObj, event);
  };

  React.useEffect(() => {
    sendSocketMessage({
      eventName: ecardSocketEventNames.room.pullRoomsData,
      data: {},
    });
  }, []);

  React.useEffect(() => {
    window.addEventListener("customPageEvent", dialogListener);
    return () => {
      window.removeEventListener("customPageEvent", dialogListener);
    };
  }, []);

  return (
    <div className="ecard-lobby-main-div">
      <div className="ecard-lobby-header-div center-container">
        <button
          className="hoverBtn mainBtn"
          onClick={() => {
            handlePlayVsBot();
          }}
        >
          play vs bot
        </button>
        <button
          className="hoverBtn mainBtn yellowBtn"
          onClick={() => setCreateNewDialogOpened(true)}
        >
          create a new game
        </button>
      </div>

      <SpikesDown text={"Game Lobby"} />

      <div className="ecard-lobby-content-div">
        <div style={{ marginTop: 50 }}></div>
        {/* 
        <button onClick={handlePlayVsBot}> play vs bot</button>
        <p>------</p>
        <CreateNewRoom />
        <p>------</p>
        <p>Active Rooms</p> */}
        <ActiveRooms isJoinDisabled={isJoinDisabled} setIsJoinDisabled={setIsJoinDisabled}/>
      </div>
      <MenuButton />
      {createNewDialogOpened && (
        <DialogComponent
          innerComponenet={CreateNewRoom}
          open={createNewDialogOpened}
          setOpen={setCreateNewDialogOpened}
        />
      )}

    {userJoinedDialogOpen && <DialogComponent
              innerComponenet={UserJoinedDialog}
              open={userJoinedDialogOpen}
              setOpen={setUserJoinedDialogOpen}
            /> }


    {declineDialogOpen && <DialogComponent
              innerComponenet={DeclineDialog}
              open={declineDialogOpen}
              setOpen={setDeclineDialogOpen}
            /> }
    </div>
  );
}

export default EcardLobby;
