

import sword from '../../img/icons/swords.svg';

import citizien from '../../img/citizen.jpg';
import slave from '../../img/slave.jpg';
import emperor from '../../img/emperor.jpg';

import slave_defeated from "../../img/slave_defeated.jpg";
import citizien_defeated from "../../img/citizen_defeated.jpg";
import emperor_defeated from "../../img/emperor_defeated.jpg";
import back from '../../img/back.jpg';

const Rule5 = () => (
    <div className="">
        <div className="">
            <div className="row-container rules-main-center-img-3">
            <img className="" src={back} alt="" />
            <img className="" src={back} alt="" />
            <img className="" src={back} alt="" />
            <img className="" src={back} alt="" />
            <img className="" src={back} alt="" />
            </div>

        </div>
        <h3 className="center-container" style={{width:"80%", marginLeft:"10%", textAlign:"center"}}>
           <span>There are 4 rounds, with each round lasting 3 turns.</span>
           <span>Each player will play once as an emperor and once as a slave in one round.</span>
           <span>The side that lost previous turn will be the one to start the next one.</span>
           <span>The one who managed to get more points will win the e-card game.</span>           
        </h3>
    </div>
)

export default Rule5;   