import React from 'react'
import { useNavigate } from 'react-router-dom';

function MenuButton() {
    const navigate = useNavigate();
  return (
    <div style={{
        position: "fixed",
        bottom: "10px",
        left: "10px",
        zIndex:9999
    }}
    className='hoverBtn'
    onClick={()=>{navigate("/menu")}}
    >

        <div className='row-container '>
        <svg width="30px" height="30px" viewBox="-6.4 -6.4 32.80 32.80" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#f3f4c0"><g id="SVGRepo_bgCarrier" strokeWidth="0" transform="translate(0,0), scale(1)"><rect x="-6.4" y="-6.4" width="32.80" height="32.80" rx="16.4" fill="#000" ></rect></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.12"></g><g id="SVGRepo_iconCarrier"> <path fill="#f3f4c0" fillRule="evenodd" d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z"></path> </g></svg>
        <span className='c214'
            style={{
                paddingLeft: 5,
                fontWeight: "bold",
                WebkitTextStrokeWidth: "0.03em",
                WebkitTextStrokeColor: "black"
            }}
        >Options</span>
      </div>

    </div>
  )
}

export default MenuButton