export const ecardSocketEventNames = {
    errorInfo : "ei",
    room: {
        pullRoomsData: 'r1',
        createNewRoom: 'r2',
        joinVsBot : 'r3',
        joinRoom : 'r4',
        closeRoom : "r5",
        acceptMatch : "r6",
        matchDeclined : "r7",
        error : "r8",
    },
    game : {
        start : "g1", // na confirm -> pa server salje obojci onda ide reddirect na igru
        playTurn : "g2",
        revealCards : "g3", // -> server sends //[ [address1, card], [address2, card], winnerIndex_0_1 = -1 ] //-1 no winners TIE
        turnWinner : "g4", // not used
        gameOverWinner : "g5",
        gameStopped : "g6",
    },

    actions : {
        requestToSignMessage : "a1",
        signedMessage : "a2",
        requestToSignMessageToClient : "a3"
    },
    wallet : {
        notifyBalance : "w1",
    }
}