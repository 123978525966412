import DialogCloseButton from '../../../components/dialogs/DialogCloseButton';
import ArrowRightButton from '../../../components/ArrowRightButton';
import { useState } from 'react';
import { useContractDataContext } from '../../../providers/ContractDataProvider';
import { convertBigIntAsStringToReadable } from '../../../web3/utils';
import { getProfilesContract, writeToContractWithValue } from '../../../web3';
import { getCookie } from '../../../utils/utils';

function CreateNewUserDialog(setDialogClose : ()=>void) {
    const [ inputUsername, setInputUsername] = useState("");
    const [errMsg , setErrMsg] = useState("");
    //
    const { contractData, signer } = useContractDataContext()

    const handleBetChange = (event : any) =>{
        setInputUsername(event.target.value.trim());
    }

    const handleCreateNewUser = async ()=>{
   
    const ref = getCookie("ref")
    try{
        await writeToContractWithValue(
            getProfilesContract(signer),
            'createProfile',
            //name , refname
            [ inputUsername , ref],
            String(contractData.profile.profileCreationFee)
            );
        setErrMsg("")
    } catch (e) {
        //@ts-ignore
        const m = e.reason
        setErrMsg(m || "Transaction canceled")
    }
     
    //   setDialogClose()
    }

  return (
    <div className='dark-txt'>
      <div className='center-container'>
      <p>Create new account</p>
        <span> User creation fee : {convertBigIntAsStringToReadable(String(contractData.profile.profileCreationFee))} ETH </span>
        <span style={{marginTop:20}}>Username : <input value={inputUsername}  onChange={handleBetChange} size={8} /> </span>
        {errMsg && <span style={{color:"red"}}>{errMsg}</span>}
      </div>
        
        <div className='row-container-end'>
          <span onClick={setDialogClose}><DialogCloseButton /></span>        
          <span onClick={handleCreateNewUser}><ArrowRightButton text={"Create"} /></span>
        </div>
    </div>
  )
}

export default CreateNewUserDialog