import React from 'react';

import citizien from '../../img/citizen.jpg';
import slave from '../../img/slave.jpg';
import emperor from '../../img/emperor.jpg';

const Rule1 = () => (
    <div className="center-container">
        <div className="row-container rules-main-center-img">
            <img className="" src={citizien} alt="" />
            <img className="" src={citizien} alt="" />
            <img className="" src={citizien} alt="" />
            <img className="" src={citizien} alt="" />
        </div>
        <div className="row-container rules-main-center-img">
            <img className="" src={slave} alt="" />
            <span className="rules-inbetween-img"> ? </span>
            <img className="" src={emperor} alt="" />
        </div>
        <h3 className="center-container" style={{width:"80%", marginLeft:"10%", textAlign:"center"}}>
            Each player has 5 cards, 4 Citizens and either Slave or Emperor.
        </h3>
    </div>
);

export default Rule1;