import React from 'react'
import DialogCloseButton from '../../../../components/dialogs/DialogCloseButton'
import ArrowRightButton from '../../../../components/ArrowRightButton'
import { useEcard } from '../../../../providers/EcardProvider'
import { ecardSocketEventNames } from '../../../../socketCommunication/ecardSocket';
import AudioPlayer from '../../../../utils/AudioPlayer';

function UserJoinedDialog(setDialogClose : ()=>void) {

  const {sendSocketMessage} = useEcard();

  const handleStart = () =>{
    sendSocketMessage({eventName:ecardSocketEventNames.room.acceptMatch, data:[]})
  }

  const handleDecline = () =>{
    sendSocketMessage({eventName:ecardSocketEventNames.room.matchDeclined, data:[]})
    setDialogClose();
  }
  return (
    <div>
      <div className='center-container'>
      <p>Another user has joined, Do you want to start the game? If you decline your room will be closed! </p>
      </div>
        
        <div className='row-container-end'>
          <span onClick={handleDecline}><DialogCloseButton text={"Decline"} /></span>        
          <span onClick={handleStart}><ArrowRightButton text={"Start"} /></span>
        </div>
        {/* <span>{`[option for private roons - share room to other player]`}</span> */}
        <AudioPlayer src={"/honkhonk.mp3"} />
    </div>
  )
}

export default UserJoinedDialog