import React, { useState } from 'react'
import Rule1 from './Rule1'
import Rule2 from './Rule2'
import Rule3 from './Rule3'
import Rule4 from './Rule4'
import Rule5 from './Rule5'
import DialogCloseButton from '../dialogs/DialogCloseButton'
import ArrowRightButton from '../ArrowRightButton'
import ArrowLeftButton from '../ArrowLeftButton'

interface RulesComponentInterface {
  open : boolean,
  setOpen : (a:boolean) => void
}

function RulesComponent(p:RulesComponentInterface) {
  const [ruleNo, setRuleNo] = useState(1)

  const handleRuleNext = () =>{
    if(ruleNo === 5){return}
    setRuleNo(p=>p+1)
  }

  const handleRuleBack = () =>{
    if(ruleNo === 1){return}
    setRuleNo(p=>p-1)
  }
  
  return (
    <div className='dialog-main-bg'>
      <div className='rules-main center-container'>
        <h1>RULES</h1>
        {ruleNo <= 1 && <Rule1 />}
        {ruleNo === 2 && <Rule2 />}
        {ruleNo === 3 && <Rule3 />}
        {ruleNo === 4 && <Rule4 />}
        {ruleNo >= 5 && <Rule5 />}
        
        <span
        className="rules-main-closebtn"
        onClick={()=>p.setOpen(false)}
        >
          <DialogCloseButton svgColor='#0078ee' txtColor='#D5D4D4' />
        </span>

        <div className="rules-main-btns">
          <span onClick={handleRuleBack}><ArrowLeftButton text={''} svgColor='white' txtColor='white'/></span>
          <span onClick={handleRuleNext}><ArrowRightButton text={''} svgColor='white' txtColor='white'/></span>
        </div>
      </div>
    </div>
    
  )
}

export default RulesComponent