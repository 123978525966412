import { config } from "../../config";

export const handleWithdrawApi = async (signature : string, message : string) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ signature, message })
    };
    const response = await fetch(`${config.restApiEndpoint}/api/action/withdraw`, requestOptions);
    const data = await response.json();
    return data
}

export const handleUserHistoryApi = async (address : string) =>{

    const bearerToken = localStorage.getItem('gamblehaus_signature') ?? ""
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}` },
    };
    const response = await fetch(`${config.restApiEndpoint}/api/action/actionHistory/${address}`, requestOptions);
    if(response.status === 200){
        const data = await response.json();
        return data
    }
    return []
}