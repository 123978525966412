import React from 'react'
import DialogCloseButton from '../../../../components/dialogs/DialogCloseButton'

function DeclineDialog(setDialogClose : ()=>void) {
  return (
    <div>
      <div className='center-container'>
      <p>The match has been canceled - room owner denied the request </p>
      </div>
        <div className='row-container-end'>
          <span onClick={setDialogClose}><DialogCloseButton /></span>        
        </div>
    </div>
  )
}

export default DeclineDialog