import React from "react";

interface DialogCloseButtonInterface {
  txtColor? : string;
  svgColor? : string;
  text?:string;
}

function DialogCloseButton(p : DialogCloseButtonInterface) {
  return (
    <div className="row-container  ">
      <div className="dialog-close-button hoverBtn">
        <p style={{color: p.txtColor?p.txtColor:"#000"}}>{p.text?p.text:"Close"}</p>
        <svg
          width="20px"
          height="20px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill={p.svgColor?p.svgColor:'#000000'}
            d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
          />
        </svg>
      </div>
    </div>
  );
}

export default DialogCloseButton;
