import React, { useEffect, useState } from "react";
import "./header.css";
import { useAccount } from "wagmi";
import { useLocation } from "react-router-dom";
import ProfileInfo from "./ProfileInfo";
import { useContractDataContext } from "../providers/ContractDataProvider";
import { useEcard } from "../providers/EcardProvider";

function Header() {
  const { isConnected } = useAccount();
  const location = useLocation();
  const { contractData } = useContractDataContext();
  const { isSocketInstanceSignedIn } = useEcard();

  const [btnVisible, setBtnVisible] = useState(false);

  useEffect(() => {
    ///ecard/game
    if (location.pathname === "/ecard/game") {
      setBtnVisible(false);
    } else {
      setBtnVisible(true);
    }
  }, [location.pathname]);

  return (
    <div className="overlay-header">
      {/* <ConnectButton></ConnectButton> */}
      {contractData.profile.username && isConnected && btnVisible && isSocketInstanceSignedIn && (
        <div id="connectbtn">
          <ProfileInfo />{" "}
        </div>
      )}
    </div>
  );
}

export default Header;
