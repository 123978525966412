import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { useContractDataContext } from './ContractDataProvider';
import { useEcard } from './EcardProvider';


interface AccountContextProviderProps {
  children: ReactNode;
}

const AccountContext = createContext(undefined);

const AccountContextProvider: React.FC<AccountContextProviderProps> = ({ children }) => {
    const { isConnected } = useAccount();
    const navigate = useNavigate();
    const location = useLocation();
    const { contractData } = useContractDataContext();
    const { isSocketInstanceSignedIn } = useEcard();
  
  useEffect(() => {
    
    if (!isConnected && location.pathname !== '/' && !contractData.profile.username) {
      navigate('/');
    } else if (isConnected && location.pathname === '/' && !!contractData.profile.username && isSocketInstanceSignedIn) {
      navigate('/menu');
    } else if (isConnected && location.pathname !== '/' && !contractData.profile.username && !isSocketInstanceSignedIn) {
      navigate('/');
    } else {}
    // else if (isConnected && location.pathname === '/' && contractData.profile.username){
    //   navigate('/menu');
    // }
  }, [contractData, isConnected, location.pathname, isSocketInstanceSignedIn]);

  return (
    <AccountContext.Provider value={undefined}>
      {children}
    </AccountContext.Provider>
  );
};

export default AccountContextProvider;
