import sword from '../../img/icons/swords.svg';

import citizien from '../../img/citizen.jpg';
import slave from '../../img/slave.jpg';
import emperor from '../../img/emperor.jpg';

import slave_defeated from "../../img/slave_defeated.jpg";
import citizien_defeated from "../../img/citizen_defeated.jpg";
import emperor_defeated from "../../img/emperor_defeated.jpg";

const Rule4 = () => (
    <div className="">
    <div className="">

        <div className="row-container rules-main-center-img-3">
        <img className="" src={emperor} alt="" />
        <span> + 1 </span>
            
        </div>

        <div className="row-container rules-main-center-img-3">
        <img className="" src={citizien} alt="" />
        <span> + 1 </span>
        </div>

        <div className="row-container rules-main-center-img-3">
        <img className="" src={slave} alt="" />
        <span> + 5 </span>
        </div>

    </div>
    <h3 className="center-container" style={{width:"80%", marginLeft:"10%", textAlign:"center"}}>
       <span> Winning as the emperor and citizen grants you one point, while winning as the slave grants you five. </span>
    </h3>
</div>
);

export default Rule4;