import React, { useEffect, useState } from 'react'
import { handleUserHistoryApi } from '../../utils/api/actions'
import { useAccount } from 'wagmi';
import { CollectionUserHistoryType, WalletHistoryDataInterface } from '../../interfaces';
import { config } from '../../config';
import { convertBigIntAsStringToReadable } from '../../web3/utils';

const ReloadButton = ({onClick}: {onClick: () => void}) =>{
  return < >
   <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="20px"
    height="20px"
    className='hoverBtn'
    style={{marginTop:"8px"}}
    onClick={onClick}
   >
    <path
        d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"
    />
</svg>

  </>
}

function WalletHistory() {
  const account = useAccount();
  const [historyData, setHistoryData] = useState<WalletHistoryDataInterface[]>([])
  const [lasTimePulled, setLastTimePulled] = useState(1)

  function isWithinSpecifiedRange() {
    const now = Date.now();
    const diff = now - lasTimePulled;
    return diff >= 5000; //5secs
  }

  const loadData = async () => {
    if(!isWithinSpecifiedRange()){return}
    setLastTimePulled(Date.now())
    const data = await handleUserHistoryApi(account.address ?? "")
    setHistoryData(data)
  }

  useEffect(()=>{
    loadData()
  },[])

  return (
    <div className='wallet-history-div'>
        <div className='wallet-history-div-header'> History <ReloadButton onClick={loadData}/> </div>
          <div className='wallet-history-div-content'>
            <aside style={{height:'10px', width:"10px"}}></aside>
            {historyData.sort((a, b) => b.timestamp - a.timestamp).map(a=><SingleWalletHistory d={a} key={a.timestamp} />)}
            {/* <div className='row-container'>+1 ETH WIN 🏆</div>
            <div className='row-container'>-100 ETH LOSE 💀</div>
            <div className='row-container'>+100 ETH DEPOSIT 📥 <a href="#">tx</a></div>
            <div className='row-container'>-100 ETH WIRTHAW 📤 <a href="#">tx</a></div>
            <div className='row-container'>-100 ETH WIRTHAW 📤 <a href="#">tx</a></div>
            <div className='row-container'>-100 ETH WIRTHAW 📤 <a href="#">tx</a></div>
            <div className='row-container'>-100 ETH WIRTHAW 📤 <a href="#">tx</a></div>
            <div className='row-container'>-100 ETH WIRTHAW 📤 <a href="#">tx</a></div>
            <div className='row-container'>-100 ETH WIRTHAW 📤 <a href="#">tx</a></div>
            <div className='row-container'>-100 ETH WIRTHAW 📤 <a href="#">tx</a></div>
            <div className='row-container'>-100 ETH WIRTHAW 📤 <a href="#">tx</a></div>
            <div className='row-container'>-100 ETH WIRTHAW 📤 <a href="#">tx</a></div>
            <div className='row-container'>-100 ETH WIRTHAW 📤 <a href="#">tx</a></div>
            <div className='row-container'>-100 ETH WIRTHAW 📤 <a href="#">tx</a></div> */}
            <aside style={{height:'10px', width:"10px"}}></aside> 
          </div>
    </div>
  )
}

export default WalletHistory



const SingleWalletHistory = (d:any) =>{

  //bad practice like this...

  const getTypeName = () => {
    switch(d.d.type) {
      case CollectionUserHistoryType.DEPOSIT:
        return 'DEPOSIT'
        case CollectionUserHistoryType.WITHDRAW:
        return 'WITHDRAW '
        case CollectionUserHistoryType.LOST:
        return 'LOST '
        case CollectionUserHistoryType.WON:
        return 'WON '
        case CollectionUserHistoryType.REFERAL_GAME_LOST:
        return 'REFERAL GAME '
        case CollectionUserHistoryType.REFERAL_PROFILE_CREATION:
        return 'REFERAL CREATE '
    }
  }

  const getRightIcon = () =>{
    switch(d.d.type) {
      case CollectionUserHistoryType.DEPOSIT:
        return '📥'
        case CollectionUserHistoryType.WITHDRAW:
        return '📤'
        case CollectionUserHistoryType.LOST:
        return '💀'
        case CollectionUserHistoryType.WON:
        return '🏆'
        case CollectionUserHistoryType.REFERAL_GAME_LOST:
        return '💸'
        case CollectionUserHistoryType.REFERAL_PROFILE_CREATION:
        return '🤝'
    }
  }

  const getPositiveNegative = () =>{
    switch(d.d.type){
      case CollectionUserHistoryType.LOST:
      case CollectionUserHistoryType.WITHDRAW:
        return <span style={{
          marginRight: "5px",
          color : "#c15151"
        }}>- {convertBigIntAsStringToReadable(d.d.amount || "0")} ETH</span>
      default:
        return <span style={{
          marginRight: "5px",
          color : "#51c175"
        }}>+ {convertBigIntAsStringToReadable(d.d.amount || "0")} ETH</span>
    }
  }

  const ifTxReturnUrl = () =>{
    if(d.d.transaction){
      return  <a style={{marginLeft:"7px"}} href={`${config.web3.explorer}/tx/${d.d.transaction}`}>tx</a>
    }
    return null
  }

  const getDate = () =>{
    const date = new Date(d.d.timestamp)
    return date.toISOString().slice(0, 16).replace("T", " ");
  }
  
  
  return (<div className='row-container'>{getPositiveNegative()} {getRightIcon()} <span style={{opacity: "0.5"}}>{getTypeName()}</span>  {ifTxReturnUrl()} <span style={{marginLeft: 10, opacity: "0.5", fontSize:14}}>{getDate()}</span></div>)
}