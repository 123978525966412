import React, { useEffect, useState } from 'react'
import { Room } from '../../../../Types'
import SingleActiveRoom from './SingleActiveRoom'
import { useEcard } from '../../../../providers/EcardProvider';
import { useAccount } from 'wagmi';
import TimerComponent from '../../../../components/TimerComponent';
import TimerDialog from '../../../../components/dialogs/TimerDialog';
import DialogComponent from '../../../../components/dialogs/DialogComponent';

// const rooms : Room[] = [
//     {
//         id : "1",
//         players : [
//             {
//                 address : "0x66f73cb28dA9B96102081B60Ff678c41a22a8485",
//                 username : "John"
//             }
//         ],
//         betAmount : 0.001,
//     },
//     {
//         id : "2",
//         players : [{
//             address : "0x66f73cb28dA9B9610208178c41a22a8485",
//             username : "Alex"
//         }],
//         betAmount : 90,
//     }
// ]

interface ActiveRoomProps { 
  isJoinDisabled : boolean;
  setIsJoinDisabled: (a:boolean) =>void
}


function ActiveRooms(p:ActiveRoomProps) {
    const account = useAccount();
    const {lobbyRooms} = useEcard();
    const [myRoom, setMyRoom] = useState<Room|null>(null);

    useEffect(()=>{
        if(!lobbyRooms){return}
        const f = lobbyRooms.filter(x => x.players[0].address === account.address );
        if(f.length>0){setMyRoom(f[0] as Room)}
        else{setMyRoom(null)}
    },[lobbyRooms])
  return (
    <div>

      {p.isJoinDisabled && (
        <DialogComponent
          innerComponenet={TimerDialog}
          open={p.isJoinDisabled}
          setOpen={()=>{}}
        />
      )}
        {myRoom && <SingleActiveRoom room={myRoom} key={myRoom.id} isJoinDisabled={p.isJoinDisabled} setIsJoinDisabled={p.setIsJoinDisabled}/>}
        {!myRoom && lobbyRooms && lobbyRooms.map(r=><SingleActiveRoom room={r} key={r.id} isJoinDisabled={p.isJoinDisabled} setIsJoinDisabled={p.setIsJoinDisabled} />)}
        {/* {rooms && rooms.map(r=><SingleActiveRoom room={r} key={r.id} />)}
        {rooms && rooms.map(r=><SingleActiveRoom room={r} key={r.id} />)}
        {rooms && rooms.map(r=><SingleActiveRoom room={r} key={r.id} />)}
        {rooms && rooms.map(r=><SingleActiveRoom room={r} key={r.id} />)}
        {rooms && rooms.map(r=><SingleActiveRoom room={r} key={r.id} />)}
        {rooms && rooms.map(r=><SingleActiveRoom room={r} key={r.id} />)}
        {rooms && rooms.map(r=><SingleActiveRoom room={r} key={r.id} />)} */}
    </div>
  )
}



export default ActiveRooms