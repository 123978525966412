import React, { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { config } from '../config';
import { useAccount } from 'wagmi';
import { ecardSocketEventNames } from '../socketCommunication/ecardSocket';
import { Room } from '../Types';
import { useNavigate } from 'react-router-dom';
import { dispatchCustomEvent, fetchEthPrice } from '../utils/utils';
import { useContractDataContext } from './ContractDataProvider';
import { convertBigIntAsStringToReadable } from '../web3/utils';
import WinnerDialog from './components/WinnerDialog';


// Define the context type
interface EcardSocketContextProps {
    startSocketInstance: ()=>void;
    sendSocketMessage: (p : {eventName:string, data:Object})=>void;
    lobbyRooms : Room[] | null,
    game: GameState | null,
    ethPrice : string;
    walletBalance: string;
    isSocketInstanceSignedIn : boolean
}

interface GamePlayer {
  wins : number,
  address : string,
  username : string
}

interface GameState {
  inGameIndex : number,
  me : GamePlayer,
  opponent : GamePlayer,
  opponentIndex : number,
  emperorFirst : number
}

interface WinnerDialogState {show:boolean; type: "winner" | "loser"}

declare global {
  interface Window {
    ecardGame: GameState | null;
  }
}

// Create the context
const EcardSocketContext = createContext<EcardSocketContextProps | undefined>(undefined);

// Define the provider component
interface EcardSocketProviderProps {
  children: React.ReactNode;
}

const EcardSocketProvider: React.FC<EcardSocketProviderProps> = ({ children }) => {
    const [lobbyRooms, setLobbyRooms] = useState< Room[] | null>(null)
    const [walletBalance , setWalletBalance] = useState("0")
    const [isSocketInstanceSignedIn, setIsSocketInstanceSignedIn] = useState(false)
    const [ethPrice, setEthPrice] = useState("1")
    const { signer } = useContractDataContext();

    const navigate = useNavigate();
    //ovaj se koristi za komunikaciju za dobijanje - citanje i za slanje socket poruka //TODO

  // State to hold the socket instance
  const [socketInstance, setSocketInstance] = useState<Socket | null>(null);
  const account = useAccount();
  const [winnerDialog, setWinnerDialog] = useState<WinnerDialogState>({show:false, type: "loser"})

  const iframeMessageSender = (method:string, params:any) =>{
    const gameIframe = document.getElementById('ecardGameIframe')
    if(gameIframe){
      //@ts-ignore
      gameIframe.contentWindow.postMessage({
        target : config.ecardIframe.targets.ecardGameFront,
        data : {
            name : config.ecardIframe.name,
            method : method,
            params : params
        }
    }, '*');
    }
}

  const handleAcceptMatch = (data : any)=> {
      ////empty list to know to show that user is waiting for the owner to start
      if(data[0]){
        dispatchCustomEvent(config.customDispatch.showUserJoinedDialog, "")
      }
  }

  const handleGameStarted = (data : any) =>{
    const ingameIndex = data[0][0] === account.address?0:1
    const opponentIndex = ingameIndex === 1?0:1;
    const empFirst = data[2][0]
    // setGame({
    //   inGameIndex : ingameIndex,
    //   opponentIndex : opponentIndex,
    //   me : {
    //     wins : 0,
    //     address : data[ingameIndex][0],
    //     username : data[ingameIndex][1]
    //   },
    //   opponent : {
    //     wins : 0,
    //     address : data[opponentIndex][0],
    //     username : data[opponentIndex][0]
    //   },
    //   emperorFirst : empFirst
    // })

    window.ecardGame  = {
      inGameIndex : ingameIndex,
      opponentIndex : opponentIndex,
      me : {
        wins : 0,
        address : data[ingameIndex][0],
        username : data[ingameIndex][1]
      },
      opponent : {
        wins : 0,
        address : data[opponentIndex][0],
        username : data[opponentIndex][0]
      },
      emperorFirst : empFirst
    }

    navigate('/ecard/game')
    
  }
  const handleOpponentCardPlaced = (data : any) =>{
    // [playerIndex, card]
    if(data[0] === window.ecardGame?.opponentIndex){
      iframeMessageSender(
        config.ecardIframe.message_methods.opponentCardPlaced,
        {}
      )
    }
  }

  const handleRevealCards = (d:any) =>{
    if(!window?.ecardGame){return}
    dispatchCustomEvent(config.customDispatch.resetTimer, "")

    iframeMessageSender(
      config.ecardIframe.message_methods.reveal,
      {
        opponentCard : d[window.ecardGame.opponentIndex][0] ,
        citizenNo : d[window.ecardGame.opponentIndex][1],
        winner :  Number(d[2]) === -1? "none" : window.ecardGame.inGameIndex === Number(d[2])? "player" : "opponent",
        points : {
          player : d[3]?d[3][window.ecardGame.inGameIndex]:0 ,
          opponent : d[3]?d[3][window.ecardGame.opponentIndex] : 0
        }
      }
    )
    
// 0
// : 
// (2) ['0x66f73cb28dA9B96102081B60Ff678c41a22a8485', 1, citizenNo]
// 1
// : 
// (2) ['0x9fFfa6E9f2e669Fca19a9EdFc463FBbdFeD3913c', 1]
// 2
// : 
// -1
  }


  const startSocketInstance =  () =>{
   
    if(socketInstance){return}
    if(!account){return}
   
    const socket = io(config.socket.ecardUrl, {
      query: {
        address :account.address,
        // once loggedin generate token that lasts 7days on 5th day login regenerate it send to the client to send back check validity server side //TODO
        //token : 
        // ask user to sign a message - server side cross check if signature matches - timestamp needs to be within 3min range
        //ili izmesti u drugi microservice za login! //TODO
          //pa ce taj da ima socket da slusa na promene u mongodb - da bi pisao tacan balance itd
          //withdrawl i deposite
        message : "dummy",
        signedMessage : "dummy",
      }
    });

    setSocketInstance(socket)
    if(socket){return socket}
    throw Object.assign(
      new Error("error while trying to connect tot the socket"),
      { code: 402 }
   )
    
  }

  const sendSocketMessage = async (p : {eventName:string, data:Object})=>{
    let socket : Socket
    //@ts-ignore
    if(!socketInstance){socket = await startSocketInstance()} else {
      socket = socketInstance
    }
    socket.emit(p.eventName, p.data)

  }

  const handleSignedMessage = async (d:any) =>{
    signer?.signMessage(String(d[0])).then((signature) => {
      localStorage.setItem('gamblehaus_signature', signature)
      sendSocketMessage({eventName : ecardSocketEventNames.actions.signedMessage, data :[signature]})
    })
  }

  const handleGameOverWinner = async (d:any) =>{
    const winnerAddress = d[0]
    const amIWinner = winnerAddress === account.address
    setWinnerDialog({type:amIWinner?"winner":"loser", show: true})
  }

  const handleBalanceNotify = async (d:any) =>{
    setWalletBalance(convertBigIntAsStringToReadable(d[0] || "0"))
    !isSocketInstanceSignedIn && setIsSocketInstanceSignedIn(true)
  }

  const handleCloseWinnerDialog = () =>{
    // close and reddirect to lobby
    setWinnerDialog({show:false, type: "loser"})
    navigate('/ecard/lobby')
  }

  // Clean up the socket connection on component unmount
  useEffect(() => {
    (async ()=>{
      const p = await fetchEthPrice()
      setEthPrice(String(p))
    })();
    if(socketInstance){
      socketInstance.on(ecardSocketEventNames.room.pullRoomsData, (data :any) =>{setLobbyRooms(data as Room[])})
      socketInstance.on(ecardSocketEventNames.room.acceptMatch, handleAcceptMatch  )
      socketInstance.on(ecardSocketEventNames.room.matchDeclined, (data : any)=>{dispatchCustomEvent(config.customDispatch.showDeclineDialog, "")})
      socketInstance.on(ecardSocketEventNames.game.start, handleGameStarted)
      socketInstance.on(ecardSocketEventNames.game.playTurn, handleOpponentCardPlaced)
      socketInstance.on(ecardSocketEventNames.game.revealCards, handleRevealCards )
      socketInstance.on(ecardSocketEventNames.game.gameOverWinner,  handleGameOverWinner)
      socketInstance.on(ecardSocketEventNames.actions.requestToSignMessage, ()=> sendSocketMessage({eventName : ecardSocketEventNames.actions.requestToSignMessage, data :[]}) )
      socketInstance.on(ecardSocketEventNames.actions.signedMessage, handleSignedMessage )
      socketInstance.on(ecardSocketEventNames.wallet.notifyBalance, handleBalanceNotify )
      


      


      

    socketInstance.onAny((eventName, ...args) => {
    
    });
    }
    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, [socketInstance]);

  // Provide the socket instance through context
  return (
    <EcardSocketContext.Provider value={{
      startSocketInstance,
      sendSocketMessage,
      lobbyRooms,
      game : window.ecardGame,
      ethPrice,
      walletBalance,
      isSocketInstanceSignedIn
      }}>
        {winnerDialog.show && <WinnerDialog type={winnerDialog.type} close={handleCloseWinnerDialog} />}
      {children}
    </EcardSocketContext.Provider>
  );
};

// Custom hook to access the socket instance
const useEcard = () => {
  const context = useContext(EcardSocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a EcardSocketProvider');
  }
  return context;
};

export { EcardSocketProvider, useEcard };
