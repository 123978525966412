import { ethers } from "ethers"

export const convertHumanReadableToNumber = (n:string) =>{
    return Number(n) * 1000000000000000000
}

export const convertBigIntAsStringToReadable = (n : string) =>{
    return ethers.formatEther(
        ethers.toBigInt(n)
    )
}