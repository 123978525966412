import { useEffect, useState } from 'react'
import { ecardSocketEventNames } from '../../../socketCommunication/ecardSocket';
import { useEcard } from '../../../providers/EcardProvider';
import { convertBigIntAsStringToReadable, convertHumanReadableToNumber } from '../../../web3/utils';
import DialogCloseButton from '../../../components/dialogs/DialogCloseButton';
import ArrowRightButton from '../../../components/ArrowRightButton';
import { roundTo2Deciamls } from '../../../utils/utils';
import { useAccount, useBalance } from 'wagmi';
import { getWalletManagerContract, writeToContractWithValue } from '../../../web3';
import { useContractDataContext } from '../../../providers/ContractDataProvider';

function DepositDialog(setDialogClose : ()=>void) {
    const {sendSocketMessage, ethPrice} = useEcard();
    const [amount, setAmount] = useState('0.00001')
    const [errMsg, setErrMsg] = useState("");
    const { signer } = useContractDataContext()
    const {walletBalance} = useEcard();
    const [txSent, setTxSent] = useState(false)

    const { address } = useAccount();
    const { data : balanceData, isError, isLoading } = useBalance({
       address: address,
    });

    const handleBetChange = (event : any) =>{
      setAmount(event.target.value);
    }

    const handelDepositDialog = async ()=>{
      if(Number(amount) === 0){setErrMsg("Deposit amount needs to be greater than 0!");return}
      if(!/^\d+(\.\d+)?$/.test(String(amount))){setErrMsg("Not a valid input number!");return}
      setTxSent(true)
      await writeToContractWithValue(
        getWalletManagerContract(signer),
        'depositEth',
        [],
        String(convertHumanReadableToNumber(String(amount)))
        );      
    }

    useEffect(()=>{
      txSent && setDialogClose()
    },[walletBalance])

  return (
    <div>
      <div className='center-container'>
      <p>Deposit</p>
      <span style={{border : "1px solid blue", padding:5, margin:15, textAlign:'center'}}>Balance : <p>{convertBigIntAsStringToReadable(String(balanceData?.value || "0"))} ETH</p></span>
        <span><input value={amount}  onChange={handleBetChange} size={8} /> ETH </span>
        <span className='opacity-c'> {roundTo2Deciamls(Number(amount) * Number(ethPrice))} $</span>
        {errMsg && <span style={{color:"red"}}>{errMsg}</span>}
      </div>
        
        <div className='row-container-end'>
          <span onClick={setDialogClose}><DialogCloseButton /></span>        
          <span onClick={handelDepositDialog}><ArrowRightButton text={"Deposit"} /></span>
        </div>
    </div>
  )
}

export default DepositDialog