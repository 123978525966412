import React from 'react'

function SpikesDown({text}:{text:string}) {
  return (
    <>
    <div className='menu-header'>
            <div className='dark-bg '>
            <p>{text}</p>
            </div>
        </div>
        
        <div className='spikes-down'></div>
    </>
  )
}

export default SpikesDown