import React from "react";

interface ArrowRightButtonInterface {
  txtColor? : string;
  svgColor? : string;
  text : string;
}

function ArrowRightButton(p: ArrowRightButtonInterface) {
  return (
    <div className="row-container  ">
    <div className="dialog-close-button hoverBtn">
      <p style={{color: p.txtColor?p.txtColor:"#000"}}>{p.text}</p>
      <svg xmlns="http://www.w3.org/2000/svg"  width={'20px'} height={'20px'}>
        <path
        fill={p.svgColor?p.svgColor:'#000000'}
        d="M10 20A10 10 0 1 0 0 10a10 10 0 0 0 10 10zM8.711 4.3l5.7 5.766L8.7 15.711l-1.4-1.422 4.289-4.242-4.3-4.347z" />
      </svg>
    </div>
  </div>
  );
}

export default ArrowRightButton;
