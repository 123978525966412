import React, { useEffect } from 'react'
import { useNavigate  } from 'react-router-dom';
import './home.css';
import { ConnectButton } from './connectButton/ConnectButton';
import { useAccount } from 'wagmi';

function Home() {
    const navigate = useNavigate();
    // const goToEcard = () =>{navigate('/ecard/lobby');}
    const { isConnected } = useAccount();

    // useEffect(()=>{
    //     if(isConnected){
    //         navigate('/menu')
    //     }
    // },[isConnected])
    return (
        <div className='home-container'>
            
            <div className="home-footer">
                <div className='spikes-up'></div>
                <div className='dark-bg'>
                    <div className="homeButtonContent">
                        <ConnectButton />
                    </div>                    
                </div>
                </div>
           
            {/* <h3>Choose a game:</h3>
            <ul>
                <li>
                    <a href="#" onClick={goToEcard}>E card Lobby</a>
                </li>
            </ul> */}
        </div>
    )
}

export default Home