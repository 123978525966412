import SpikesDown from '../../components/SpikesDown'
import MenuButton from '../../components/MenuButton'
import WalletHistory from './WalletHistory'
import DialogComponent from '../../components/dialogs/DialogComponent';
import DepositDialog from './dialog/DepositDialog';
import WithdrawDialog from './dialog/WithdrawDialog';
import { useState } from 'react';

function WalletPage() {
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  return (
    <div className='menu-main-div'>
      <SpikesDown text={"Wallet"} />
    <div >
      <div className="menu-items-menu">
            <p onClick={()=>{setOpenDeposit(true)}}>Deposit</p>
            <p onClick={()=>{setOpenWithdraw(true)}}>Withdraw</p>
        </div>
        {/* <div style={{marginLeft:"20%", marginTop:"-2%"}}><WalletHistory /></div> */}
        </div>
        <WalletHistory />
      <MenuButton />
      {openDeposit && <DialogComponent
          innerComponenet={DepositDialog}
          open={openDeposit}
          setOpen={setOpenDeposit}
      /> }
      {openWithdraw && <DialogComponent
        innerComponenet={WithdrawDialog}
        open={openWithdraw}
        setOpen={setOpenWithdraw}
      /> }
    </div>
  )
}

export default WalletPage
