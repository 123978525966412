import React from 'react'

interface ComponentProps {
    type : "winner" | "loser",
    close : () => void,
}

function WinnerDialog(props : ComponentProps) {

  return (
    <div className={props.type === "winner"?'winnerDialog winnerDialog-win':"winnerDialog"}>
         <p>{
                props.type==="winner"?
                `You have won the game!`:
                `You have lost the game!`
            }
         </p>
         <button onClick={props.close} type="button" className='connectButton-style hoverBtn'>
                    <span className='skew-fix' >Back to lobby</span>
                  </button>
    </div>
  )
}

export default WinnerDialog