
import sword from '../../img/icons/swords.svg';

import citizien from '../../img/citizen.jpg';
import slave from '../../img/slave.jpg';
import emperor from '../../img/emperor.jpg';

import slave_defeated from "../../img/slave_defeated.jpg";
import citizien_defeated from "../../img/citizen_defeated.jpg";
import emperor_defeated from "../../img/emperor_defeated.jpg";


const Rule2 = () => (
    <div className="">
        <div className="">

            <div className="row-container rules-main-center-img-3">
            <img className="" src={emperor} alt="" />
                
                <img className="" src={sword} alt="" />
                <img className="" src={citizien_defeated} alt="" />
                
            </div>

            <div className="row-container rules-main-center-img-3">
            <img className="" src={citizien} alt="" />
                <img className="" src={sword} alt="" />
                <img className="" src={slave_defeated} alt="" />
            </div>

            <div className="row-container rules-main-center-img-3">
            <img className="" src={citizien} alt="" />
                <img className="" src={sword} alt="" />
                <img className="" src={citizien} alt="" />
            </div>

        </div>
        <h3 className="center-container" style={{width:"80%", marginLeft:"10%", textAlign:"center"}}>
           <span> The Citizen card represents the common man, and cannot defeat the Emperor who sits at the top.</span>
           <span> It can, however, defeat the Slave, who resides at the very bottom of the system.</span>
           <span> Two citizen against each other results in a tie.</span>
        </h3>
    </div>
)

export default Rule2;