import React from 'react'
import TimerComponent from '../TimerComponent'

function TimerDialog(setDialogClose : ()=>void) {

  return (
    <div>
      <div className='center-container'>
        <TimerComponent duration={30} reset={1}/>
      </div>
        
    </div>
  )
}

export default TimerDialog