import * as ethers from 'ethers';
import { config } from "../config";
import { http, createConfig } from 'wagmi';
import { fantomTestnet } from 'wagmi/chains';

// export const wagmiContractConfig = createConfig({
//     chains: [fantomTestnet],
//     transports: {
//       [fantomTestnet.id]: http(),
//     },
//   });

export const getProfilesContract = (signer : any) => {
    return new ethers.Contract(config.web3.profilesContract.contract, config.web3.profilesContract.abi, signer);

    // return {
    //     addressOrName: config.web3.profilesContract.contract,
    //     contractInterface: config.web3.profilesContract.abi
    // }

    // return  {
    //     address: config.web3.profilesContract.contract,
    //     abi: config.web3.profilesContract.abi,
    //   }
}

export const getWalletManagerContract = (signer : any) => {
  return new ethers.Contract(config.web3.walletManagerContract.contract, config.web3.walletManagerContract.abi, signer);
}

export const readContractData = async (contract: ethers.Contract, methodName: string, args: any[] = []) => {
  
    try {
      const data = await contract[methodName](...args);
      return data;
    } catch (error) {
      console.error('Error reading contract data:', error);
      throw error;
    }
  }

  /*
  // Contract address and ABI
const contractAddress = "0xYourContractAddressHere";
const ABI = [/* Your contract ABI here * /];

// Create a contract instance
const contract = new ethers.Contract(contractAddress, ABI, signer);

// Call the writeToContractWithValue function
const valueToSend = "1"; // 1 ETH
const overrides = { gasLimit: 21000, gasPrice: ethers.utils.parseUnits("1", "gwei") };
  */
  export const writeToContractWithValue = async (contract: ethers.Contract, methodName: string, args: any[] = [], value: string, overrides?: ethers.Overrides) => {
    try {
       // Convert the value to wei if it's not already in wei
      //  const valueInWei = ethers.parseEther(value);
   
       // Combine the value with any existing overrides
       const combinedOverrides = { ...overrides, value: value };
   
       // Call the contract method with the value included in the overrides
       const tx = await contract[methodName](...args, combinedOverrides);
   
       // Wait for the transaction to be mined
       const receipt = await tx.wait();
       return receipt;
    } catch (error) {
       throw error
      //  throw error;
    }
   }
   


export const writeToContract = async (contract: ethers.Contract, methodName: string, args: any[] = [], overrides?: ethers.Overrides) => {
try {
    // Assuming the methodName is a function that changes the contract's state
    // and requires a signer (a wallet) to send the transaction.
    // The overrides object can be used to specify additional transaction parameters like gas limit, gas price, or value.
    const tx = await contract[methodName](...args, overrides);
    // The transaction is sent, but it's not yet mined. You can wait for it to be mined.
    const receipt = await tx.wait();
    return receipt;
} catch (error) {
    console.error('Error writing to contract:', error);
    throw error;
}
}
