import React, { useEffect, useRef } from 'react';

const AudioPlayer = ({ src } : {src : string}) => {
  const audioRef = useRef<any>(null);

  useEffect(() => {
    const audio = new Audio(src);
    audioRef.current = audio;

    // Play the audio as soon as the component mounts
    audio.play();

    // Cleanup function to stop the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0; // Reset the audio to the beginning
    };
  }, [src]); // Re-run the effect if the src changes

  return null; // This component doesn't render anything visible
};

export default AudioPlayer;