import React from 'react'
import { Room } from '../../../../Types';
import { useAccount } from 'wagmi';
import { useEcard } from '../../../../providers/EcardProvider';
import { ecardSocketEventNames } from '../../../../socketCommunication/ecardSocket';
import { roundTo2Deciamls } from '../../../../utils/utils';
import { ethers } from 'ethers';

interface SingleActiveRoomProps {
    room : Room;
    isJoinDisabled:boolean;
    setIsJoinDisabled:(a:boolean) => void;
}

function SignleActiveRoom(prop:SingleActiveRoomProps) {
  const account = useAccount();
  const {sendSocketMessage, ethPrice} = useEcard();

  const handleRemoveRoom = () =>{
    sendSocketMessage({eventName:ecardSocketEventNames.room.closeRoom, data:{}})
  }

  const handleJoinRoom = (rId : string) => {
    prop.setIsJoinDisabled && prop.setIsJoinDisabled(true)
    sendSocketMessage({eventName:ecardSocketEventNames.room.joinRoom, data:[rId]})
  }
  return (
    <div className='row-container c214'>

      <div className="ecard-room-main">
        <div className="ecard-room-border">
        <div>
        <p> Bet: {ethers.formatEther(String(prop.room.betAmount))} ETH   <span className='opacity-c'>{ roundTo2Deciamls(Number(ethers.formatEther(String(prop.room.betAmount)))* Number(ethPrice))} $</span></p>
        <p className='italic'> By:  {prop.room.players[0].username}  </p>
        </div>
        {account.address === prop.room.players[0].address && <span className='hoverBtn cancelBtn c214' onClick={handleRemoveRoom}>cancel</span>}
        {
        account.address !== prop.room.players[0].address && 
        <span className={`c214 joinBtn ${prop.isJoinDisabled?"opacity-c":"hoverBtn"}`} onClick={()=>{!prop.isJoinDisabled && handleJoinRoom(prop.room.id)}}> Join </span>
        // disabled={disabledJoin}
        }
        
        </div>
      </div>
      
        {/* <p> Bet: {prop.room.betAmount} | </p>
        <p> By:  {prop.room.players[0].username} | </p>
        {account.address === prop.room.players[0].address && <button onClick={handleRemoveRoom}>cancel</button>}
        {
        account.address !== prop.room.players[0].address && 
        <button disabled={disabledJoin} onClick={()=>{handleJoinRoom(prop.room.id)}}> Join </button>
        }
         */}
    </div>
  )
}

export default SignleActiveRoom