import { ConnectButton as RainbowConnectButton } from '@rainbow-me/rainbowkit';
import './connectButton.css';
import { useState } from 'react';
import DialogComponent from '../../../components/dialogs/DialogComponent';
import CreateNewUserDialog from '../dialog/CreateNewUserDialog';
import { useContractDataContext } from '../../../providers/ContractDataProvider';
import LoaderComponent from '../../../components/LoaderComponent';
import { useEcard } from '../../../providers/EcardProvider';

export const ConnectButton = () => {
  const [createNewUserDialogOpened, setCreateNewUserDialogOpened] = useState(false);
  const { contractData } = useContractDataContext()
  const { startSocketInstance } = useEcard();

  return (
    <RainbowConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated');

        return (
          <>
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button onClick={openConnectModal} type="button" className='connectButton-style hoverBtn'>
                    <span className='skew-fix' >Connect Wallet</span>
                  </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button onClick={openChainModal} type="button">
                    Wrong network
                  </button>
                );
              }
              return (
                <div style={{ display: 'flex', gap: 12 }}>
                  {contractData.profile.profileCreationFee ?
                  <button
                    onClick={()=>{
                      contractData.profile.username?
                      startSocketInstance():
                      setCreateNewUserDialogOpened(true)
                    }}
                    
                    type="button"
                    className='connectButton-style hoverBtn dots-blue-bg'
                    style={{ display: 'flex', alignItems: 'center'}}
                  >
                    <span className='skew-fix' style={{width:"100%",}} >
                      {contractData.profile.username?"SIGN A MESSAGE":"CREATE ACCOUNT"}
                      </span>
                  </button> :
                  <span>
                    <LoaderComponent size={30} />
                  </span>
                   }
                </div>
              );
            })()}
          </div>
          {createNewUserDialogOpened && (
          <DialogComponent
            innerComponenet={CreateNewUserDialog}
            open={createNewUserDialogOpened}
            setOpen={setCreateNewUserDialogOpened}
          />
        )}
          </>
        );
      }}
    </RainbowConnectButton.Custom>
  );
};
