export enum CollectionUserHistoryType {
    WITHDRAW = 1,
    DEPOSIT = 2,
    LOST = 3,
    WON = 4,
    REFERAL_PROFILE_CREATION = 5,
    REFERAL_GAME_LOST = 6
   }

export enum CollectionUserHistoryStatus {
    PENDING = 1, 
    FINISHED = 2
}   


export interface WalletHistoryDataInterface {
    type: CollectionUserHistoryType,
    amount: string,
    timestamp: number,
    transaction: string | undefined
    status: CollectionUserHistoryStatus
}