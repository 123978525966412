import React from 'react'

function LoaderComponent(p:LoaderComponentInterface) {
  return (
    <span className="loader"
    style={{
        width : p.size ?? 50,
        height : p.size ?? 50,
        color : p.color ?? "blue",
    }}
    ></span>
  )
}

export default LoaderComponent

interface LoaderComponentInterface {
    size?:number;
    color?:string;
}