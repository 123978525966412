import React, { createContext, useState, useEffect, ReactNode, useMemo, useContext } from 'react';
import { ethers, ContractInterface, Contract } from 'ethers';
import { getProfilesContract, readContractData } from '../web3';
import { useAccount } from 'wagmi';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';



interface ContractDataContextValue {
  contractData: {
    profile : {
        username? : string,
        profileCreationFee?: string
    }
  };
  isLoading: boolean;
  signer : ethers.JsonRpcSigner | null,
  signMessage : (m:string)=>Promise<string>
}

const defaultState : ContractDataContextValue = {
    contractData: {
        profile : {
            username : undefined,
            profileCreationFee: undefined
        }
      },
      isLoading: false,
      signer : null,
      signMessage :(m:string)=>new Promise((resolve,reject)=>{return resolve(m)})
  }

const ContractDataContext = createContext<ContractDataContextValue | undefined>(undefined);

interface ContractDataProviderProps {
  children: ReactNode;
}

const ContractDataProvider: React.FC<ContractDataProviderProps> = ({ children }) => {
  const { address } = useAccount();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contractData, setContractData] = useState(defaultState.contractData)
  const [signer, setSigner] = useState<any>(null);
  const signMessage = async (m:string) =>{
    const wallet = new ethers.Wallet(signer);
    const signedMessage = await wallet.signMessage(m);
    return signedMessage

  }
  const navigate = useNavigate();
  
  const fetchSigner = async () => {
        try{
            const provider = new ethers.BrowserProvider(window.ethereum);
            const s = await provider.getSigner();
            setSigner(s);
        } catch(e) {console.error("error : ", e)}
    };

  useEffect(() => {
    if(!window.ethereum){return}
    !signer && fetchSigner()

    const fetchContractData = async () => {
        if(!signer){return}
      try {
        if(!contractData?.profile?.username){
            try{
                const pData = await readContractData(getProfilesContract(signer), 'getProfileByAddress', [String(address)]);
                setContractData(p=> _.merge(p, {
                    profile : {
                        username: String(pData["2"])
                    }
            }))
            // navigate('/menu');
            } catch{console.log("Can not fetch username.")}
        }

        if(!contractData?.profile?.profileCreationFee){
            try{
                const price = await readContractData(getProfilesContract(signer), 'profileCreationFee', []);
                setContractData(p=> _.merge(p, {
                        profile : {
                            profileCreationFee: String(price)
                        }
                }))
            }catch{console.log("Can not fetch profile creation fee.")}
        }
        

        // const readContractData: Contract[] = [
        //   {
        //     addressOrName: contractAddress,
        //     contractInterface: contractABI as ContractInterface,
        //     functionName: 'myFunction1',
        //     args: [],
        //   },
        //   {
        //     addressOrName: contractAddress,
        //     contractInterface: contractABI as ContractInterface,
        //     functionName: 'myFunction2',
        //     args: ['arg1', 'arg2'],
        //   },
        // ];

        // const data = await Promise.all(
        //   readContractData.map((contractData) =>
        //     signer.getContractAt(contractData.contractInterface, contractData.addressOrName)[contractData.functionName](...contractData.args)
        //   )
        // );

        // setContractData(data);
      } catch (error) {
        //
      } finally {
        isLoading && setIsLoading(false);
      }
    };

    fetchContractData();

    const provider = new ethers.BrowserProvider(window.ethereum);
    provider.on('block', fetchContractData);

    return () => {
      provider.off('block', fetchContractData);
    };
  }, [signer]);

  return (
    <ContractDataContext.Provider value={{ contractData, isLoading, signer, signMessage }}>
      {children}
    </ContractDataContext.Provider>
  );
};

export { ContractDataContext, ContractDataProvider };

export const useContractDataContext = () => useContext(ContractDataContext) || defaultState