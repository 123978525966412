

import sword from '../../img/icons/swords.svg';

import citizien from '../../img/citizen.jpg';
import slave from '../../img/slave.jpg';
import emperor from '../../img/emperor.jpg';

import slave_defeated from "../../img/slave_defeated.jpg";
import citizien_defeated from "../../img/citizen_defeated.jpg";
import emperor_defeated from "../../img/emperor_defeated.jpg";


const Rule3 = () => (
    <div className="">
        <div className="">
            <div className="row-container rules-main-center-img-3">
            <img className="" src={slave} alt="" />
                <img className="" src={sword} alt="" />
                <img className="" src={emperor_defeated} alt="" />
            </div>

        </div>
        <h3 className="center-container" style={{width:"80%", marginLeft:"10%", textAlign:"center"}}>
           <span> The Emperor represents the one at the top of society. </span>
           <span> This card can defeat the citizen, but will lose to the Slave.</span>

           <span> The Emperor represents the one at the top of society. This card can defeat the citizen, but will lose to the Slave. </span>

           <span> The Slave is presented as the one at the very bottom of society. Seeing as how it has nothing to lose, it can overthrow the Emperor in one last attempt at revenge. However, it can be beaten by citizen.</span> 
        </h3>
    </div>
);

export default Rule3;