import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./header/Header";
import Home from "./pages/home/Home";
import EcardGame from "./pages/ecard/game/EcardGame";
import EcardLobby from "./pages/ecard/lobby/EcardLobby";
import MenuPage from "./pages/MenuPage/MenuPage";
import LeaderboardPage from "./pages/leaderboardPage/LeaderboardPage";
import WalletPage from "./pages/walletPage/WalletPage";
import { useEffect } from "react";
import { getCookie } from "./utils/utils";


const App = () => {
  const location = useLocation();

  useEffect(()=>{
    const cref = getCookie("ref");

    if(cref !== ""){return}
    const query = new URLSearchParams(location.search);
    const ref = query.get('ref');
    if(!ref){return}
    document.cookie = `ref=${ref};max-age=473040000`; 
  }, [])
  return (
    <div>
    <Header />
    <Routes>
        <Route index path="/" element={<Home />} />
        <Route  path="/menu" element={<MenuPage />} />
        <Route  path="/wallet" element={<WalletPage />} />
        
        <Route  path="/ecard/game" element={<EcardGame />} />
        <Route  path="/ecard/lobby" element={<EcardLobby />} />
        {/* <Route  path="/ecard/leaderboard" element={<LeaderboardPage />} /> */}
        

        
        
    </Routes>
    </div>
  );
};

export default App;
