import React from 'react'
import { useContractDataContext } from '../providers/ContractDataProvider';
import { useEcard } from '../providers/EcardProvider';

function ProfileInfo() {
  const { walletBalance } = useEcard();
  const { contractData } = useContractDataContext();
  return (
    <div className='profileInfo'>
        <span>{contractData.profile.username}</span>
        <span>{walletBalance} ETH</span>
    </div>
  )
}

export default ProfileInfo