import React, { useEffect, useState } from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

interface TimerComponentInterface {
    duration : number;
    reset: number;
}

function TimerComponent(p:TimerComponentInterface) {
    const [key, setKey] = useState(0);

    useEffect(()=>{
        setKey(p=>p+1)
    },[p.reset])

  return (
    <CountdownCircleTimer
    key={key}
    isPlaying
    duration={p.duration}
    colors={['#004777', '#F7B801', '#A30000', '#A30000']}
    colorsTime={[7, 5, 2, 0]}
    onComplete={() => {
      return { shouldRepeat: false, delay: 1.5 }
    }}
  >
    {renderTime}
  </CountdownCircleTimer>
  )
}

export default TimerComponent


const renderTime = ({ remainingTime }: { remainingTime :any }) => {
    if (remainingTime === 0) {
      return <div></div>;
    }
  
    return (
      <div className="center-container">
        <div className="value">{remainingTime}</div>
        <div className="text">seconds</div>
      </div>
    );
  };

  